/* src/components/Catalog.css */

/* Import Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.catalog-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #f0f4f8;
  font-family: 'Roboto', sans-serif;
}

.catalog-page h1 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #007cfb; /* Updated title color */
  position: relative;
}

.catalog-page h1::after {
  content: '';
  width: 80px;
  height: 4px;
  background-color: #002a5c; /* Updated line color */
  display: block;
  margin: 20px auto 0;
  border-radius: 2px;
}

.catalogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
}

.catalog-card {
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.catalog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.card-header {
  background: linear-gradient(135deg, #007cfb, #005bb5);
  padding: 20px;
  text-align: center; /* Center the content inside the header */
}

.catalog-name {
  margin: 0;
  color: #ffffff !important; /* Ensures the title text is white */
  font-weight: bold;
  font-size: 1.4rem;
  word-wrap: break-word;
  text-align: center; /* Center the title text */
}

.pdf-preview {
  flex-grow: 1;
  cursor: pointer;
  position: relative;
  background-color: #e0e7ef;
  height: 300px; /* Adjust the height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.pdf-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.loading-placeholder {
  color: #555;
  font-size: 1rem;
}

.download-pdf-btn {
  background-color: #004aad;
  color: #ffffff;
  padding: 15px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.download-pdf-btn:hover {
  background-color: #e85c50;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

@media (hover: hover) {
  .download-pdf-btn:hover {
    background-color: #e85c50;
  }
}