/* src/pages/Document/Documents.css */

/* Container Styling */
.documents-container {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
}

.documents-container h1 {
  text-align: center;
  font-size: 2.5rem;
  color: var(--primary-color, #007cfb);
  margin-bottom: 30px;
}


.documents-container h1::after {
  content: '';
  width: 80px;
  height: 4px;
  background-color: #002a5c; /* Updated line color */
  display: block;
  margin: 20px auto 0;
  border-radius: 2px;
}
/* Error Message */
.error-message {
  text-align: center;
  color: #dc3545;
  margin-bottom: 20px;
  font-size: 1.1rem;
}

/* No Documents Message */
.no-documents {
  text-align: center;
  color: #666;
  font-size: 1.2rem;
}

/* Documents Grid */
.documents-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Document Card */
.document-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.document-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}

.document-logo img {
  max-width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 8px;
  margin-bottom: 15px;
}

.document-name h3 {
  font-size: 1.2rem;
  color: var(--dark-text-color, #333333);
  margin-bottom: 10px;
}

.document-pdf a {
  display: inline-block;
  padding: 8px 16px;
  background-color: var(--primary-color, #007cfb);
  color: #ffffff;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.document-pdf a:hover {
  background-color: var(--hover-color, #005bb5);
  transform: scale(1.05);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .document-logo img {
    height: 120px;
  }

  .document-name h3 {
    font-size: 1rem;
  }

  .document-pdf a {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
}
