/* CategoryPage.css */

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  font-family: 'DM Sans', sans-serif;
}

.unique-category-page {
  padding: 2rem 0;
}

.unique-category-card {
  border: 1px solid #ddd;
  transition: box-shadow 0.3s, transform 0.3s;
  border-radius: 20px; /* Curved outlines */
  overflow: hidden;
  padding: 0; /* Remove padding to better control card content */
  position: relative;
  background-color: #fff;
  text-align: left;
  cursor: pointer; /* Ensure the cursor indicates it's clickable */
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure all cards have the same height */
}

.unique-category-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.unique-category-img-container {
  width: 100%;
  aspect-ratio: 1 / 1; /* Maintains a 1:1 aspect ratio */
  background-color: #f9f9f9; /* Optional: Placeholder background */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative; /* Ensure child elements are positioned correctly */
  
}

.unique-category-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
  object-position: center; /* Centers the image within the container */
}

.unique-card-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Pushes the button to the bottom */
}

.unique-card-title {
  font-size: 18px;
  margin-bottom: 0.5rem;
  color: #1239ac; /* Darker color */
  font-weight: 700; /* Darker font */
  text-decoration: none; /* Remove underline */
}

.unique-card-text {
  font-size: 15px;
  color: #007cfb; /* Description color */
  flex-grow: 1; /* Ensures the text takes up available space */
}

.unique-btn-download {
  margin-top: 1rem; /* Increased margin for better spacing */
  font-size: 16px; /* Slightly larger font size */
  padding: 0.6rem 1.4rem; /* Increased height and padding */
  align-self: center; /* Center the button horizontally */
  background-color: #004aad; /* Button color */
  color: #fff;
  border: none;
  border-radius: 12px; /* Button with curved corners */
  text-transform: uppercase;
  transition: background-color 0.3s ease; /* Smooth transition */
  text-align: center;
  text-decoration: none;
}

.unique-btn-download:hover {
  background-color: #007cfb; /* Change to specified color on hover */
  color: #fff; /* Ensure text color stays white */
}

.unique-no-products {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
  margin-top: 2rem;
}

