/* src/components/Home.css */

/* Import Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Root Variables for Easy Customization */
:root {
  --primary-color: #007cfb;
  --hover-color: #005bb5;
  --light-text-color: #ffffff;
  --dark-text-color: #333333;
  --background-color: #ffffff;
  --card-background: #f9f9f9;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --accent-color: #ff9900;
}

/* Apply Roboto font to the entire home container */
.home-container {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  box-sizing: border-box; /* Ensures padding and borders are included in width */
}

/* General Section Styling */
.section {
  padding: 40px 20px;
  text-align: center;
  background: var(--light-bg-color, #f9f9f9);
  border-bottom: 1px solid #e0e0e0;
}

.section:last-child {
  border-bottom: none;
}

/* Slider Section */
.slider-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 80vh; /* Adjust as needed */
}

.slider {
  position: relative;
  height: 100%;
}

.slide {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
}

.slide.active {
  display: block;
}

.slide video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay for better text readability */
  z-index: 0;
}

.slider-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-align: center;
  max-width: 80%;
  z-index: 1; /* Ensures content is above the overlay */
  width: 90%;
}

.slider-info {
  margin-bottom: 20px;
}

.slider-info h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 15px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
}

.slider-info p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 20px;
  color: #e0e0e0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Slider Actions Container */
.slider-actions {
  display: flex;
  gap: 15px; /* 15px gap between buttons */
  justify-content: center;
  flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
}

/* Primary Button Styles */
.slider-actions .btn {
  background-color: var(--primary-color, #007cfb);
  color: var(--light-text-color, #ffffff);
  padding: 12px 24px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  border: none;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  font-size: 1rem;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px; /* Ensures both buttons have the same width */
  text-align: center;
}

.slider-actions .btn:hover {
  background-color: var(--hover-color, #005bb5);
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Demo Button Styles */
.slider-actions .btn.demo {
  background-color: transparent;
  border: 2px solid var(--light-text-color, #ffffff);
  color: var(--light-text-color, #ffffff);
  transition: background-color 0.3s, color 0.3s, transform 0.3s, box-shadow 0.3s;
  width: 200px; /* Ensures both buttons have the same width */
  text-align: center;
}

.slider-actions .btn.demo:hover {
  background-color: var(--light-text-color, #ffffff);
  color: var(--primary-color, #007cfb);
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .slider-actions {
    flex-direction: column; /* Stack buttons vertically */
    gap: 15px; /* Maintain 15px gap */
  }

  .slider-actions .btn,
  .slider-actions .btn.demo {
    width: 100%; /* Full width on mobile for better tap targets */
  }

  .slider-content {
    padding: 0 10px; /* Add padding to prevent content from touching edges */
  }

  .slider-info h1 {
    font-size: 2rem;
  }

  .slider-info p {
    font-size: 1rem;
  }

  /* Adjust Next/Prev Buttons Positioning on Mobile */
  .control.prev,
  .control.next {
    top: 45%; /* Slightly lower to prevent overlapping with slider content */
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .control.prev {
    left: 10px; /* Move closer to the left edge */
  }

  .control.next {
    right: 10px; /* Move closer to the right edge */
  }
}

/* Slider Controls */
.control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 12px;
  cursor: pointer;
  font-size: 24px;
  color: #ffffff;
  z-index: 2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.control:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.control.prev {
  left: 20px;
}

.control.next {
  right: 20px;
}

/* Remove default React Slick arrows */
.slick-prev,
.slick-next {
  display: none !important;
}

/* Brands Section */
.brands-info-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  background: #f9f9f9;
  padding: 40px 20px;
}

.brands-card {
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.6s ease, box-shadow 0.6s ease;
}

.brands-card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.15);
}

.brands-image-container {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}

.brands-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.brands-content {
  padding: 20px;
}

.brands-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.brands-content li {
  position: relative;
  padding-left: 35px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #333333;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.brands-content li::before {
  content: '★';
  font-family: 'Pacifico', cursive;
  color: #ffcc00;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.brands-content li:hover {
  color: var(--primary-color, #007cfb);
}

.brands-content li:hover::before {
  transform: translateY(-50%) scale(1.2);
  color: #ff9900;
}

/* Catalog Container */
.catalog-container {
  background-color: var(--light-bg-color, #f9f9f9);
  padding: 20px;
  border-radius: 12px;
  text-align: center;
 
}


.catalog-container p {
  font-size: 1.3rem;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.6;
}

.catalog-button {
  background-color: var(--primary-color, #007cfb);
  color: var(--light-text-color, #ffffff);
  padding: 20px 60px;
  
  
  border: none;
  border-radius: 50px;
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.4s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  animation: pulse 2s infinite;
}

.catalog-button + .catalog-button {
  margin-left: 30px; /* Adjust the value as needed */
}

.catalog-button + .catalog-button {
  margin-top: 30px; /* Adjust the value as needed */
}

.catalog-button:hover {
  transform: scale(1.1);
  background-color: var(--hover-color, #005bb5);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
}

/* Responsive Adjustments for the Button */
@media (max-width: 768px) {
  .catalog-button {
    width: 100%; /* Full width on mobile for better tap targets */
    max-width: none; /* Remove max-width to allow full stretching */
    padding: 15px 20px; /* Adjust padding if needed */
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
  
  /* Optional: Adjust margin for vertical alignment on mobile */
  .catalog-button + .catalog-button {
    margin-left: 0;
    margin-top: 15px; /* Adds space above the second button */
  }
  
  .catalog-container {
    flex-direction: column; /* Stack buttons vertically on mobile */
    gap: 15px;              /* Adds gap between buttons */
  }
}

/* Certificates Section */
.certificate-section {
  padding: 40px 20px;
  background: var(--light-bg-color, #f9f9f9);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.certificate-logo-container {
  flex: 0 1 calc(25% - 30px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.certificate-logo {
  max-height: 150px;
  width: auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.certificate-logo:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive Adjustments for Certificates Section */
@media (max-width: 768px) {
  .certificate-logo-container {
    flex: 0 1 calc(50% - 20px); /* Two logos per row */
  }
}



/* References Section */
.references-container {
  padding: 80px 40px;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.references-header {
  font-family: 'Poppins', sans-serif;
  font-size: 42px;
  font-weight: 700;
  color: var(--dark-text-color);
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

.references-header::after {
  content: '';
  position: absolute;
  width: 80px;
  height: 4px;
  background-color: var(--primary-color);
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}

/* References Grid */
.references-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.reference-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.reference-card:hover,
.reference-card:focus {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  outline: none;
}

.reference-logo {
  max-width: 100%;
  max-height: 100px;
  object-fit: contain;
}

/* Show All References Button on Mobile */
.show-all-references-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--light-text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.show-all-references-btn:hover {
  background-color: var(--hover-color);
}

/* Hide the "show all" button on desktop */
@media (min-width: 769px) {
  .show-all-references-btn {
    display: none;
  }
}

/* Show the "show all" button on mobile */
@media (max-width: 768px) {
  .show-all-references-btn {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: var(--light-text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }

  .show-all-references-btn:hover {
    background-color: var(--hover-color);
  }

  /* Adjust references grid to show two per row */
  .references-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Adjust reference-card to fit two per row */
  .reference-card {
    flex: 0 1 calc(50% - 20px);
  }
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  position: relative;
  max-width: 500px;
  width: 90%;
  text-align: center;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--dark-text-color);
}

.modal-logo {
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 20px;
}

/* References Grid Accessibility */
.reference-card:focus {
  box-shadow: 0 0 0 3px var(--primary-color);
}

/* Map Section Styling */
.map-section {
  padding: 40px 20px;
  text-align: center;
  background: var(--light-bg-color, #f9f9f9);
}

.map-title {
  font-family: 'Poppins', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: var(--dark-text-color);
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

.map-title::after {
  content: '';
  position: absolute;
  width: 80px;
  height: 4px;
  background-color: var(--primary-color);
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}

/* Stats Section */
.stats-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  padding: 60px 20px;
  background-color: #eaf1ff;
}

.stat {
  background-color: var(--primary-color, #007cfb);
  color: #ffffff;
  text-align: center;
  padding: 40px 20px;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.stat-icon {
  font-size: 3rem;
  margin-bottom: 15px;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.stat-label {
  font-size: 1.2rem;
}

.stat:hover {
  transform: translateY(-5px);
}

/* Leaflet Marker Animation */
.custom-marker {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0000;
  font-size: 24px;
  animation: bounce 2s infinite;
  transform-origin: center bottom;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  /* Adjust certificate logos */
  .certificate-logo-container {
    flex: 0 1 calc(50% - 20px); /* Two logos per row */
  }

  /* Adjust references grid to show two per row */
  .references-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Adjust reference-card to fit two per row */
  .reference-card {
    flex: 0 1 calc(50% - 20px);
  }

  /* Adjust slider actions for mobile */
  .slider-actions {
    flex-direction: column;
  }
}

/* Ensure references grid shows all 47 references after clicking the button */
@media (min-width: 769px) {
  /* Hide the "show all" button on desktop */
  .show-all-references-btn {
    display: none;
  }
}
