/* src/components/ProductManagement/ProductForm.css */

/* ====== Form Container ====== */
.product-form-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 30px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* ====== Heading ====== */
.product-form-container h1 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #007cfb; /* Primary blue color */
  font-weight: bold;
}

/* ====== Form Styling ====== */
.product-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1.1rem;
  color: #333333;
}

.required {
  color: red;
  margin-left: 4px;
}

.form-control {
  padding: 12px 15px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-control:focus {
  border-color: #007cfb; /* Primary blue focus */
  outline: none;
  box-shadow: 0 0 5px rgba(0, 124, 251, 0.5);
}

.form-control-file {
  padding: 6px 12px;
}

.form-control-file:focus {
  border-color: #007cfb;
  outline: none;
}

/* ====== Image Upload Wrapper ====== */
.image-upload-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.image-upload {
  flex: 1 1 calc(50% - 15px); /* Two images per row */
  min-width: 200px;
  height: 150px;
  border: 2px dashed #cccccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  position: relative;
}

.image-upload:hover {
  background-color: #f0f8ff; /* Light blue on hover */
  border-color: #007cfb;
}

.upload-placeholder {
  text-align: center;
  color: #999999;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.uploaded-image:hover {
  transform: scale(1.05);
}

/* ====== Delete Button ====== */
.delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 8px;
  background: rgba(231, 76, 60, 0.8); /* Semi-transparent red */
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.delete-btn:hover {
  background: rgba(192, 57, 43, 0.8); /* Darker red on hover */
}

/* ====== Submit Button ====== */
.submit-btn {
  padding: 12px 30px;
  background-color: #007cfb; /* Primary blue color */
  color: #ffffff;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  align-self: flex-end;
}

.submit-btn:hover {
  background-color: #005bb5; /* Darker blue on hover */
  transform: translateY(-2px);
}

.submit-btn:active {
  transform: translateY(0);
}

/* ====== Image Preview Modal ====== */
.modal-image {
  border-radius: 10px;
}

/* ====== Promotional Input Styling ====== */
.promotional-input {
  border: 2px solid #007cfb; /* Primary blue border */
  padding: 10px;
  border-radius: 8px;
  background-color: #e7f1ff; /* Light blue background */
}

.promotional-input label {
  font-weight: bold;
  color: #005bb5; /* Darker blue label */
}

/* ====== Success and Error Messages ====== */
.success-message {
  background-color: #d1e7dd; /* Light greenish-blue */
  color: #0f5132;
  border: 1px solid #badbcc;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.1rem;
}

.error-message {
  background-color: #f8d7da; /* Light red */
  color: #842029;
  border: 1px solid #f5c6cb;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.1rem;
}

/* ====== Responsive Design ====== */
@media (max-width: 992px) {
  .product-form-container {
    padding: 35px 25px;
  }

  .product-form-container h1 {
    font-size: 2rem;
  }

  .form-group label {
    font-size: 1rem;
  }

  .submit-btn {
    font-size: 1rem;
    padding: 10px 25px;
  }

  .image-upload {
    flex: 1 1 calc(100% - 15px); /* One image per row on smaller screens */
  }
}

@media (max-width: 576px) {
  .product-form-container {
    padding: 25px 15px;
  }

  .product-form-container h1 {
    font-size: 1.8rem;
  }

  .submit-btn {
    width: 100%;
    padding: 10px 0;
  }

  .image-upload-wrapper {
    gap: 10px;
  }
}
