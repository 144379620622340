/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

/* CSS Variables */
:root {
  --primary-color: #007cfb;
  --secondary-color: #28a745;
  --accent-color: #b1ff33;
  --dark-bg-color: #1d212e;
  --light-bg-color: #f4faff;
  --text-color: #1d212e;
  --light-text-color: #ffffff;
  --hover-color: #0056b3;
  --link-hover-color: #82cc09;
  --font-family: 'DM Sans', sans-serif;
  --transition: all 0.3s ease;
}

/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Header Top */
.header-top {
  background: var(--dark-bg-color);
  padding: 10px 0;
}

.header-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.header-top-left,
.header-top-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header-top-left span {
  margin-right: 15px;
  color: var(--light-text-color);
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: var(--transition);
}

.header-top-left span:hover {
  color: var(--primary-color);
}

.header-icon {
  color: var(--primary-color);
  margin-right: 8px;
}

.login-icon {
  color: var(--primary-color);
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: 10px;
  transition: var(--transition);
}

.login-icon:hover {
  color: var(--hover-color);
}

.demo-button {
  background-color: var(--secondary-color);
  color: var(--light-text-color);
  padding: 8px 20px;
  border-radius: 25px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  font-size: 0.9rem;
  line-height: normal;
  letter-spacing: 0.5px;
  transition: var(--transition);
}

.demo-button:hover {
  background-color: var(--light-text-color);
  color: var(--primary-color);
}

/* Header Logo Area */
.header-logo-area {
  padding: 25px 0;
  background: var(--light-bg-color);
}

.header-logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.logo img {
  max-width: 186px;
  height: auto;
}

.header-contact-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.contact-info-item {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.contact-info-item .icon {
  color: var(--primary-color);
  margin-right: 10px;
  font-size: 1.5rem;
}

.contact-info-item .content {
  display: flex;
  flex-direction: column;
}

.contact-info-item .title {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 4px;
  color: var(--text-color);
}

.contact-info-item a {
  font-weight: 400;
  font-size: 0.9rem;
  color: #7b7d83;
  text-decoration: none;
  transition: var(--transition);
}

.contact-info-item a:hover {
  color: var(--primary-color);
}

/* Menu Area */
.menu-area {
  background: var(--primary-color);
  position: relative;
  z-index: 1000;
}

.menu-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.menu-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Navigation */
.navigation {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation li {
  position: relative;
}

.navigation li a,
.navigation li .menu-title {
  color: var(--light-text-color);
  font-weight: 700;
  font-size: 1rem;
  padding: 20px 15px;
  display: block;
  text-decoration: none;
  transition: var(--transition);
}

.navigation li a:hover,
.navigation li .menu-title:hover {
  background: rgba(0, 0, 0, 0.1);
}

.menu-title {
  cursor: pointer;
  display: block;
}

/* Sub-menu Styling */
.navigation .sub-menu {
  display: none;
  position: absolute;
  background: var(--light-text-color);
  top: 100%;
  left: 0;
  min-width: 220px;
  z-index: 1001;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  padding: 10px 0;
}

.navigation li:hover > .sub-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.navigation .sub-menu li a {
  color: var(--text-color);
  padding: 10px 15px;
  text-decoration: none;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  transition: background 0.2s;
}

.navigation .sub-menu li a:hover {
  background: rgba(0, 124, 251, 0.1);
  color: var(--link-hover-color);
}

.submenu-icon {
  margin-right: 8px;
  color: var(--primary-color);
  font-size: 1rem;
}

/* Redesigned Drop-down Menu Content */
.navigation .sub-menu li {
  border-bottom: 1px solid #eaeaea;
}

.navigation .sub-menu li:last-child {
  border-bottom: none;
}

/* Remove bullet points from sub-menus */
.navigation .sub-menu,
.mobile-navigation .sub-menu {
  list-style: none;
  padding-left: 0;
}

.navigation .sub-menu li,
.mobile-navigation .sub-menu li {
  list-style: none;
}

/* Shared Sub-menu Styles */
.sub-menu {
  background: var(--light-text-color);
  padding: 10px 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.sub-menu li a {
  color: var(--text-color);
  padding: 10px 15px;
  text-decoration: none;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  transition: background 0.2s;
}

.sub-menu li a:hover {
  background: rgba(0, 124, 251, 0.1);
  color: var(--link-hover-color);
}

.submenu-icon {
  margin-right: 8px;
  color: var(--primary-color);
  font-size: 1rem;
}

/* Hide Mobile Header on Desktop */
.mobile-header,
.mobile-menu-overlay,
.mobile-menu {
  display: none;
}

/* Search Container */
.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 10px;
  color: #007cfb;
}

.search-container input {
  padding: 8px 12px;
  padding-left: 30px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 200px;
  transition: border-color 0.3s, width 0.3s;
}

.search-container input:focus {
  border-color: var(--primary-color);
  outline: none;
  width: 300px;
}

/* Search Results */
.search-results,
.search-results-mobile {
  position: absolute;
  top: 42.5px;
  right: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.search-results-mobile {
  top: 50px;
  left: 20px;
  right: 20px;
  max-height: 50vh;
}

.search-result-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-result-item:hover {
  background-color: #f5f5f5;
}

.search-result-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
}

/* Logout Popup */
.logout-popup {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #333;
  color: var(--light-text-color);
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0.9;
  z-index: 1003;
  transition: var(--transition);
}

/* Mobile Styles */
@media screen and (max-width: 992px) {
  .header-contact-info {
    display: none;
  }

  /* Show Mobile Header */
  .mobile-header {
    display: flex;
    align-items: center;
    width: 100%;
  }

  /* Hide Desktop Navigation */
  .navigation {
    display: none;
  }

  /* Mobile Menu Icon */
  .mobile-menu-icon {
    display: block;
    color: var(--light-text-color);
    cursor: pointer;
    font-size: 1.5rem;
    padding: 15px;
    background-color: var(--primary-color);
  }

  /* Mobile Menu Items */
  .mobile-menu-items {
    display: flex;
    margin-left: 10px;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
  }

  .mobile-menu-link {
    color: var(--light-text-color);
    font-weight: 700;
    font-size: 1rem;
    text-decoration: none;
    position: relative;
  }

  .mobile-menu-link:hover {
    color: var(--hover-color);
  }

  /* Dropdown in Mobile Header */
  .mobile-menu-link.menu-item-has-children .menu-title {
    cursor: pointer;
    display: block;
  }

  .mobile-menu-link {
    position: relative; /* Added for correct positioning */
  }

  .mobile-menu-link .sub-menu-mobile-header {
    position: absolute;
    top: 100%;
    left: -30px;
    background-color: var(--light-text-color);
    width: 150px;
    padding: 10px 0;
    z-index: 1000;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    list-style: none; /* Remove bullet points */
    margin: 5px;
  }

  .mobile-menu-link .sub-menu-mobile-header li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .mobile-menu-link .sub-menu-mobile-header li a {
    color: var(--text-color);
    padding: 10px 15px;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .mobile-menu-link .sub-menu-mobile-header li a:hover {
    background: rgba(0, 124, 251, 0.1);
    color: var(--link-hover-color);
  }

  /* Mobile Menu Overlay */
  .mobile-menu-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
  }

  /* Mobile Menu */
  .mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    width: 80%;
    max-width: 300px;
    height: 100vh;
    background-color: var(--primary-color);
    z-index: 1001;
    overflow-y: auto;
    transition: left 0.3s ease;
    padding-top: 20px;
  }

  .mobile-menu.open {
    left: 0;
  }

  .mobile-menu-close {
    text-align: right;
    padding: 15px 20px;
    color: var(--light-text-color);
    font-size: 1.5rem;
    cursor: pointer;
  }

  .mobile-navigation {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .mobile-navigation li a,
  .mobile-navigation li .menu-title {
    padding: 15px 20px;
    color: var(--light-text-color);
    font-weight: 700;
    font-size: 1rem;
    display: block;
    text-decoration: none;
    transition: var(--transition);
  }

  .mobile-navigation li a:hover,
  .mobile-navigation li .menu-title:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  /* Mobile Sub-menu Specific Styles */
  .mobile-navigation .sub-menu {
    display: none;
  }

  .mobile-navigation .menu-item-has-children.open > .sub-menu {
    display: block;
  }

  .mobile-navigation .sub-menu li a {
    padding-left: 30px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    padding: 10px 15px;
  }

  .mobile-navigation .sub-menu li a:hover {
    background: rgba(0, 124, 251, 0.1);
    color: var(--link-hover-color);
  }

  /* Search Container Mobile */
  .search-container {
    display: none;
  }

  .mobile-search-container {
    padding: 15px 20px;
    position: relative;
  }

  .search-container-mobile {
    display: flex;
    align-items: center;
    position: relative;
  }

  .search-input-mobile {
    flex-grow: 1;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin: 0 10px;
  }

  .search-icon-mobile {
    color: var(--light-text-color);
    font-size: 1.2rem;
  }

  .cancel-icon-mobile {
    color: var(--light-text-color);
    font-size: 1.2rem;
    cursor: pointer;
  }

  .search-results-mobile {
    position: absolute;
    top: 60px;
    left: 0;
    right: 20px;
    max-height: 50vh;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 8px;
    z-index: 1002;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  }

  /* Adjust Header Top */
  .header-top-container {
    flex-direction: row;
    justify-content: space-between;
  }

  /* Adjust Social Media Icons */
  .header-top-left span .social-text {
    display: none;
  }

  /* Adjust Demo Button */
  .demo-button {
    padding: 6px 10px;
    font-size: 0.8rem;
  }

  /* Center Logo */
  .header-logo-container {
    justify-content: center;
  }

  .logo {
    margin: 0 auto;
  }
}

/* For Mobile Phones */
@media screen and (max-width: 576px) {
  .mobile-menu-link {
    font-size: 0.9rem;
  }

  .mobile-navigation li a,
  .mobile-navigation li .menu-title {
    font-size: 0.9rem;
  }

  .mobile-navigation .sub-menu li a {
    font-size: 0.8rem;
  }

  .logo img {
    max-width: 150px;
  }
}
