/* src/components/AboutUs.css */

/* Importing AOS styles */
@import 'aos/dist/aos.css';

/* Root Variables for Consistent Theming */
:root {
  --primary-blue: #002a5c;
  --secondary-blue: #005b96;
  --light-blue: #cce7ff;
  --white: #ffffff;
  --black: #000000;
  --gray: #f5f5f5;
  --font-family-body: 'Noto Sans', sans-serif;
  --font-family-heading: 'Noto Serif', serif;
  --transition-speed: 0.3s;
}


/* Global Container */
.about-us-container {
  padding: 60px 20px;
  background-color: var(--gray);
  font-family: var(--font-family);
  overflow-x: hidden; /* Prevents horizontal scrolling */
  box-sizing: border-box; /* Ensures padding and borders are included in width */
}

/* Section Styling */
.about-us-section {
  margin-bottom: 80px;
  background-color: var(--white);
  padding: 60px 40px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

/* Section Title */
.section-title {
  font-size: 2.5rem;
  color: var(--primary-blue);
  margin-bottom: 40px;
  position: relative;
  text-align: center;
  z-index: 1;
  font-weight: bold; /* Enhanced font weight */
  line-height: 1.2; /* Improved readability */
}

/* Decorative Underline */
.section-title::after {
  content: '';
  width: 60px;
  height: 4px;
  background-color: var(--secondary-blue);
  display: block;
  margin: 10px auto 0;
  border-radius: 2px;
}

/* Section Content */
.section-content, .mission-content, .vision-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Embedded Video */
.who-are-we-section iframe {
  width: 100%;
  max-width: 800px;
  height: 450px;
  border: none;
  border-radius: 12px;
  transition: transform var(--transition-speed);
}

.who-are-we-section iframe:hover {
  transform: scale(1.02);
}

/* Mission and Vision Sections */
.our-mission-section,
.our-vision-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
  flex-wrap: wrap;
}

.mission-content,
.vision-content {
  width: 48%;
  text-align: left;
  margin-bottom: 20px;
}

.mission-content h2,
.vision-content h2 {
  font-size: 2.5rem;
  color: var(--primary-blue);
  margin-bottom: 20px;
  font-weight: bold; /* Enhanced font weight */
}

/* List Styling */
.mission-list,
.vision-list {
  list-style: none; /* Removed default bullets */
  padding: 0;
}

/* Bullet Points Alignment and Padding */
.mission-list li,
.vision-list li {
  display: flex; /* Use Flexbox for alignment */
  align-items: flex-start; /* Align icon and text vertically at the top */
  padding-left: 10px; /* Space between icon and text */
  margin-bottom: 15px;
  font-size: 1.125rem;
  color: var(--black);
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity var(--transition-speed) ease-out, transform var(--transition-speed) ease-out;
  line-height: 1.4; /* Enhanced readability */
  font-weight: 300;
  letter-spacing: 0.2px;
}

/* Icon Styling */
.mission-list li .icon,
.vision-list li .icon {
  color: var(--secondary-blue);
  font-size: 1rem; /* Adjusted icon size */
  margin-right: 15px; /* Space between icon and text */
  margin-top: 5px; /* Vertical alignment with text */
  align-self: baseline;
}

/* Bullet Points Animation */
.mission-list li,
.vision-list li {
  opacity: 0;
  transform: translateX(-20px);
}

.mission-section-animate .mission-list li,
.vision-section-animate .vision-list li {
  opacity: 1;
  transform: translateX(0);
}

.mission-list li {
  transition-delay: 0.1s;
}

.vision-list li {
  transition-delay: 0.1s;
}

/* Image Containers */
.mission-image-container,
.vision-image-container {
  width: 48%;
}

.mission-image-container img,
.vision-image-container img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 12px;
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
}

.mission-image-container img:hover,
.vision-image-container img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */

/* Tablets and Smaller Screens */
@media (max-width: 992px) {
  .about-us-container {
    padding: 40px 15px;
  }

  .section-title {
    font-size: 2rem;
  }

  .who-are-we-section iframe {
    height: 300px;
  }

  .our-mission-section,
  .our-vision-section {
    flex-direction: column;
    align-items: center;
  }

  .mission-content,
  .vision-content,
  .mission-image-container,
  .vision-image-container {
    width: 100%;
  }

  .mission-content,
  .vision-content {
    text-align: center;
  }

  .mission-list li,
  .vision-list li {
    padding-left: 5px; /* Reduced padding */
  }

  /* Reorder the mission image on mobile */
  .our-mission-section .mission-image-container {
    order: 1;
  }

  .our-mission-section .mission-content {
    order: 2;
  }
}

/* Mobile Devices */
@media (max-width: 576px) {
  .about-us-container {
    padding: 30px 10px;
  }

  .section-title {
    font-size: 1.75rem;
  }

  .who-are-we-section iframe {
    height: 200px;
  }

  .mission-content,
  .vision-content {
    font-size: 1rem;
  }

  .mission-list li,
  .vision-list li {
    font-size: 1rem;
  }

  /* Further reorder the mission image on very small screens if necessary */
  .our-mission-section .mission-image-container {
    order: 1; /* Ensure image is on top */
  }

  .our-mission-section .mission-content {
    order: 2; /* Text below image */
  }
}

/* Back to Top Button */
.back-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: var(--secondary-blue);
  color: var(--white);
  border: none;
  padding: 15px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color var(--transition-speed), transform var(--transition-speed);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-to-top:hover {
  background-color: var(--primary-blue);
  transform: translateY(-5px);
}

@media (max-width: 576px) {
  .back-to-top {
    bottom: 20px;
    right: 20px;
    padding: 10px;
  }

  .back-to-top svg {
    font-size: 1rem;
  }
}
