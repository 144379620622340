/* src/pages/Document/DocumentsForm.css */

.documents-form-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 40px 30px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
}

.documents-form-container h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.2rem;
  color: #333;
}

.form-field {
  margin-bottom: 25px;
}

.form-field label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: #333;
  font-size: 1.1rem;
}

.input-wrapper {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #666;
  font-size: 1.2rem;
}

.input-wrapper input[type="text"] {
  width: 100%;
  padding: 12px 12px 12px 40px; /* Adjust padding for icon */
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: inherit;
}

.input-wrapper input[type="text"]:focus {
  border-color: #007cfb;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 124, 251, 0.2);
}

/* File Input Styling */
.file-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.file-input-icon {
  color: #666;
  font-size: 1.2rem;
  margin-right: 10px;
}

.file-input-wrapper input[type="file"] {
  opacity: 0;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-input-text {
  flex-grow: 1;
  padding: 12px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 1rem;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-input-wrapper:hover .file-input-text {
  background-color: #e6e6e6;
}

/* Submit Button */
.btn-submit {
  width: 100%;
  padding: 14px;
  background-color: #007cfb;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.btn-submit:hover {
  background-color: #005bb5;
}

.btn-submit:active {
  transform: scale(0.98);
}

.btn-submit:focus {
  outline: 2px solid #005bb5;
  outline-offset: 2px;
}

/* Feedback Messages */
.message {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.message.success {
  color: #28a745;
}

.message.error {
  color: #dc3545;
}

.message.loading {
  color: #007cfb;
  font-size: 1rem;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .documents-form-container {
    padding: 30px 20px;
  }

  .documents-form-container h1 {
    font-size: 1.8rem;
  }

  .btn-submit {
    font-size: 1.1rem;
  }
}
