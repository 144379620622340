/* CatalogManagement.css */

.catalog-management-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 40px 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
}

.catalog-management-container h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
  color: #333;
}

.catalog-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.catalog-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #eaeaea;
}

.catalog-item:last-child {
  border-bottom: none;
}

.catalog-management-name {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  flex: 1 1 auto;
  margin-right: 20px;
  word-break: break-word;
}

.catalog-actions {
  display: flex;
  gap: 10px;
  flex-shrink: 0;
}

.btn {
  padding: 10px 18px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.1s ease;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn:focus {
  outline: 2px solid #005bb5;
  outline-offset: 2px;
}

.btn.view-pdf {
  background-color: #007cfb;
  color: #ffffff;
}

.btn.view-pdf:hover {
  background-color: #005bb5;
}

.btn.delete {
  background-color: #ff4d4d;
  color: #ffffff;
}

.btn.delete:hover {
  background-color: #cc0000;
}

.message {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.message.error {
  color: #ff4d4d;
}

.no-catalog-message {
  text-align: center;
  margin-top: 20px;
  font-size: 1.1rem;
  color: #666;
}

@media (max-width: 600px) {
  .catalog-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .catalog-actions {
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .btn {
    flex: 1 1 48%;
    text-align: center;
  }
}
