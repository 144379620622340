/* src/pages/Document/DocumentsManagement.css */

.documents-management-container {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
}

.documents-management-container h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem;
  color: #333;
}

/* Error Message */
.message.error {
  text-align: center;
  color: #dc3545;
  margin-bottom: 20px;
  font-size: 1.1rem;
}

/* No Documents Message */
.no-documents-message {
  text-align: center;
  color: #666;
  font-size: 1.2rem;
}

/* Documents Grid */
.documents-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Document Item */
.document-item {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.document-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}

.document-logo img {
  max-width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 8px;
  margin-bottom: 15px;
}

.document-name h3 {
  font-size: 1.2rem;
  color: var(--dark-text-color, #333333);
  margin-bottom: 10px;
}

.document-actions {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

/* Action Buttons */
.btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-view {
  background-color: #28a745; /* Green */
  color: #ffffff;
}

.btn-view:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.btn-delete {
  background-color: #dc3545; /* Red */
  color: #ffffff;
}

.btn-delete:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .document-logo img {
    height: 120px;
  }

  .document-name h3 {
    font-size: 1rem;
  }

  .btn {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
}
