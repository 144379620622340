/* src/pages/ProductManagement/ProductManagement.css */

/* ====== Container Styling ====== */
.product-management {
  max-width: 1000px;
  margin: 40px auto;
  padding: 30px 40px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* ====== Heading ====== */
.product-management h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem;
  color: #007cfb; /* Primary blue color */
  font-weight: 700;
}

/* ====== Add Product Button ====== */
.add-product-button-container {
  text-align: right;
  margin-bottom: 20px;
}

.add-product-button {
  padding: 10px 20px;
  background-color: #007cfb; /* Primary blue color */
  color: #ffffff;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.add-product-button:hover {
  background-color: #005bb5; /* Darker blue on hover */
  transform: translateY(-2px);
}

.add-product-button:active {
  transform: translateY(0);
}

/* ====== Feedback Messages ====== */
.alert {
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 15px 20px;
  font-size: 1rem;
}

.alert-success {
  background-color: #d1e7dd; /* Light greenish-blue */
  color: #0f5132;
  border: 1px solid #badbcc;
}

.alert-danger {
  background-color: #f8d7da; /* Light red */
  color: #842029;
  border: 1px solid #f5c2c7;
}

/* ====== Search and Sort Controls ====== */
.product-management .mb-4 {
  margin-bottom: 1.5rem !important;
}

.product-management .mb-2 {
  margin-bottom: 0.5rem !important;
}

/* ====== Product List Styling ====== */
.product-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #e7f3ff; /* Light blue background */
  margin-bottom: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.product-item:hover {
  background-color: #d0e7ff; /* Slightly darker blue on hover */
  box-shadow: 0 4px 12px rgba(0, 124, 251, 0.1);
}

.product-title {
  font-size: 1.1rem;
  color: #333333;
  flex: 1;
}

/* ====== Product Actions ====== */
.product-actions {
  display: flex;
  gap: 10px;
  margin-left: 20px;
}

/* ====== Delete Button Styling ====== */
.product-delete-btn {
  padding: 8px 14px;
  background-color: #dc3545; /* Bootstrap danger color */
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.product-delete-btn svg {
  margin-right: 6px;
}

.product-delete-btn:hover {
  background-color: #bb2d3b; /* Darker red on hover */
  transform: translateY(-2px);
}

.product-delete-btn:active {
  transform: translateY(0);
}

/* ====== Pagination Styles ====== */
.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
}

.pagination__link {
  padding: 8px 12px;
  border: 1px solid #007cfb; /* Primary blue border */
  border-radius: 4px;
  color: #007cfb;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
}

.pagination__link:hover {
  background-color: #007cfb;
  color: #ffffff;
}

.pagination__link--active {
  background-color: #005bb5; /* Darker blue */
  color: #ffffff;
}

.pagination__link--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* ====== Confirmation Modal Styles ====== */
.confirmation-modal .modal-content {
  border-radius: 12px;
}

.confirmation-modal .modal-header {
  border-bottom: none;
}

.confirmation-modal .modal-footer {
  border-top: none;
}

/* ====== Responsive Design ====== */
@media (max-width: 768px) {
  .product-management {
    padding: 25px 20px;
  }

  .product-management h1 {
    font-size: 2rem;
  }

  .product-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-actions {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
    justify-content: flex-start;
  }

  .product-delete-btn {
    width: 100%;
    justify-content: center;
  }

  .add-product-button {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .product-management {
    padding: 20px 15px;
  }

  .product-management h1 {
    font-size: 1.8rem;
  }

  .add-product-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .product-delete-btn {
    font-size: 0.8rem;
    padding: 6px 12px;
  }

  .pagination__link {
    padding: 6px 10px;
    font-size: 0.8rem;
  }
}
