/* src/components/Footer.css */

/* Footer Section */
.footer {
  background-color: #002a5c;
  color: #ffffff;
  padding: 60px 20px 20px;
}

.footer .footer-section h5 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  /* Removed cursor: pointer; from here */
}

/* Add cursor: pointer only to the 'Bize Ulaşın' title */
.footer .footer-section.contact h5 {
  cursor: pointer;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.footer-section.contact {
  padding-top: 24px;
  padding-bottom: 96px;
}

.footer-section.contact a {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.footer-section.contact a:hover {
  text-decoration: underline;
}

.footer-section.contact h5 {
  text-align: center;
  font-size: 72px;
  font-weight: 900;
  margin-bottom: 72px;
}

.footer-section.contact address {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 24px;
  margin: 0;
}

.footer-section.contact p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  font-size: 24px;
  margin: 0;
}

@media (max-width: 767px) {
  .footer-section.contact {
    padding-top: 0;
    padding-bottom: 72px;
  }
  .footer-section.contact h5 {
    font-size: 48px; /* Adjusted for small screens */
    margin-bottom: 32px; /* Optional: Adjust margin for smaller screens */
  }
  .footer-section.contact address {
    gap: 18px;
  }
  .footer-section.contact p {
    gap: 18px;
    font-size: 18px;
  }
  .footer-section-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

.footer .footer-section address,
.footer .footer-section ul,
.footer .footer-section p {
  color: #ffffff;
}

.footer .footer-section ul {
  list-style: none;
  padding: 0;
}

.footer .footer-section ul li {
  margin-bottom: 10px;
}

.footer .footer-section ul li a {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.footer .footer-section ul li a:hover {
  text-decoration: underline;
}

.footer .footer-bottom {
  margin-top: 20px;
}

.footer .footer-bottom p {
  color: #ffffff;
  text-align: center;
  margin: 0;
}
