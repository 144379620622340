/* ProductDetailPage.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Roboto:wght@400;500&display=swap');

/* Container and Page Styling */
.product-detail-page {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  color: #333333;
  padding: 20px;
}

/* Breadcrumb Styling */
.custom-breadcrumb {
  font-size: 0.9rem;
  color: #333333;
  margin-bottom: 20px;
}

.custom-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: '>'; /* Custom separator */
  color: #6c757d;
  margin: 0 5px;
}

.custom-breadcrumb .breadcrumb-item a {
  color: #333333;
  text-decoration: none; /* Remove underline */
}

.custom-breadcrumb .breadcrumb-item a:hover {
  color: #333333; /* Keep color consistent without hover effects */
}

/* Thumbnail Sidebar */
.thumbnail-sidebar {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.thumbnail-wrapper {
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, border-color 0.3s ease;
  padding: 3px;
}

.thumbnail-wrapper.selected {
  border-color: #1239ac;
  transform: scale(1.05);
}

.thumbnail-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 3px;
}

/* Main Image */
.main-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px; /* Adjusted for smaller size */
  width: 100%;
  margin-bottom: 20px;
}

.main-image {
  width: 100%;
  max-width: 600px; /* Ensures image doesn't exceed container */
  height: auto;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.main-image:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Product Information Section */
.product-info-section {
  padding: 0 20px;
}

.product-category {
  color: #7b7d83; /* Updated color for category */
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.product-detail-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #1239ac;
  margin-bottom: 15px;
}

.product-description {
  font-family: 'Helvetica', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #007cfb;
  margin-bottom: 20px;
  font-weight: 600;
}

/* Tabs */
.product-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  gap: 10px;
}

.product-tabs .nav-link {
  color: #555555;
  font-weight: 600;
  padding: 8px 15px;
  border-radius: 5px;
}

.product-tabs .nav-link.active {
  color: #ffffff;
  background-color: #007cfb;
}

.product-details {
  list-style: none;
  padding-left: 0;
  font-size: 0.95rem;
}

.product-details li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}

.product-details li::before {
  content: '✔';
  position: absolute;
  left: 0;
  color: #1239ac;
}

/* Packet-Card Styling */
.packet-sizes {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start; /* Align to the left */
}

.packet-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 200px;
  text-align: center;
}

.packet-weight,
.packet-dimensions {
  margin-bottom: 10px;
  font-size: 0.95rem;
  color: #333333;
}

.packet-weight strong,
.packet-dimensions strong {
  color: #1239ac;
}

/* Download Buttons */
.download-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-start; /* Align to the left */
  flex-wrap: wrap;
}

.download-button {
  background-color: #1239ac;
  color: #ffffff;
  border-radius: 8px;
  font-size: 0.9rem;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.download-button:hover {
  background-color: #0e2a8a;
}

.download-button .fas {
  font-size: 1.1rem;
}

/* Enlarged Zoom Control Button Styling */
.zoom-control {
  position: absolute;
  background: #ffffff;
  border-radius: 50%;
  width: 50px; /* Increased width */
  height: 50px; /* Increased height */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem; /* Larger icon size */
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  right: 15px; /* Position on the right */
}

.zoom-control:hover {
  background-color: #f0f0f0;
}

.zoom-in {
  top: 30px; /* Adjusted for larger buttons */
}

.zoom-out {
  top: 95px; /* Adjusted for larger buttons */
}

.close-zoom {
  top: 160px; /* Adjusted for larger buttons */
}

/* Responsive Video Container */
.video-responsive {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  padding-top: 25px;
  height: 0;
  overflow: hidden;
  width: 100%; /* Ensure full width */
  max-width: 800px; /* Increase max-width for larger display on desktop */
  margin: 0 auto; /* Center the video */
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Subtle shadow */
}

/* Optional: Adjust iframe height on smaller screens */
@media (max-width: 768px) {
  .video-responsive {
    padding-bottom: 75%; /* 4:3 Aspect Ratio */
    max-width: 100%; /* Full width on mobile */
  }
}

/* ProductDetailPage.css */

/* Responsive Adjustments */
@media (max-width: 992px) {
  .product-detail-page {
    padding: 15px;
  }

  .content-container {
    flex-direction: column;
    align-items: center;
  }

  .product-info-section {
    text-align: center;
    padding: 0;
  }

  .main-image-container {
    max-width: 100%; /* Removes max-width constraint */
    margin-right: 0;
    margin-bottom: 20px;
  }

  .thumbnail-sidebar {
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
    flex: none;
  }

  .packet-sizes,
  .download-buttons {
    justify-content: center; /* Center align on smaller screens */
  }
}

/* ProductDetailPage.css */

/* Existing styles above... */

/* Responsive Adjustments for Mobile Devices */
@media (max-width: 768px) {
  /* 1. Align Product Information to Left */
  .product-info-section {
    text-align: center; /* Override any center alignment */
    padding: 0 10px; /* Optional: Adjust padding for better spacing */
  }

  /* 2. Align Bullet Points to Left */
  .product-details {
    text-align: left; /* Ensure the list is left-aligned */
    padding-left: 0; /* Remove any left padding */
  }

  .product-details li {
    padding-left: 25px; /* Maintain space for the checkmark */
    text-align: left; /* Ensure text within list items is left-aligned */
  }

  /* 3. Align Package Sizes to Left */
  .packet-sizes {
    justify-content: flex-start; /* Align items to the left */
    text-align: left; /* Ensure text inside is left-aligned */
  }

  .packet-card {
    max-width: 100%; /* Allow packet cards to take full width */
    width: 100%; /* Ensure full width on mobile */
    text-align: left; /* Align text inside to left */
  }

  /* 4. Align Download Buttons to Left */
  .download-buttons {
    justify-content: flex-start; /* Align buttons to the left */
    flex-direction: column; /* Stack buttons vertically for better mobile UX */
    align-items: flex-start; /* Align items to the start */
    gap: 10px; /* Add spacing between buttons */
  }

  .download-button {
    width: 100%; /* Make buttons full width */
    max-width: none; /* Remove any max-width restrictions */
    text-align: left; /* Ensure button text is left-aligned */
  }

  /* Optional: Adjust Thumbnail Sidebar for Mobile */
  .thumbnail-sidebar {
    flex-direction: row; /* Display thumbnails in a row on mobile */
    justify-content: center; /* Center thumbnails */
    width: 100%; /* Take full width */
    overflow-x: auto; /* Allow horizontal scrolling if needed */
  }

  .thumbnail-wrapper {
    flex: 0 0 auto; /* Prevent thumbnails from stretching */
  }

  .thumbnail-image {
    width: 50px; /* Adjust thumbnail size if needed */
    height: 50px;
  }
}
