/* src/pages/Login.css */

/* Importing Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

/* Reset and Base Styles for Login Page Only */
.login-page {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align to top for better visibility */
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(135deg, #f0f4f8, #d9e4f5);
    text-align: center;
    padding: 60px 20px; /* Increased top padding for better spacing */
    font-family: 'Roboto', sans-serif;
}

.login-container {
    background: #ffffff;
    width: 600px; /* Increased width to accommodate the title */
    max-width: 90%; /* Ensure responsiveness on smaller screens */
    padding: 60px 50px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: 20px; /* Margin to push container higher */
    position: relative;
    box-sizing: border-box; /* Include padding in width calculations */
}

.login-container::before {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    width: 60px;
    height: 60px;
    background: rgba(110, 164, 255, 0.2);
    border-radius: 50%;
    z-index: -1;
}

.login-container::after {
    content: '';
    position: absolute;
    bottom: -20px;
    right: -20px;
    width: 60px;
    height: 60px;
    background: rgba(110, 164, 255, 0.2);
    border-radius: 50%;
    z-index: -1;
}

.login-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.login-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 36px; /* Increased font size for better prominence */
    color: #0E4579;
    margin-bottom: 30px; /* Increased from 25px to 30px for more space */
    text-transform: uppercase;
    letter-spacing: 1.5px;
    position: relative;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: visible; /* Allow overflow */
}

.login-title::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 4px;
    background-color: #6ea4ff;
    bottom: -20px; /* Increased from -15px to -20px to move the line further below the title */
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
}

.login-subtitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px; /* Increased font size from 12px to 14px */
    color: #0E4579;
    margin-bottom: 3rem; /* 3 rem spacing between subtitle and first input */
    letter-spacing: 0.5px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-text-area {
    position: relative;
    width: 100%;
    margin-bottom: 0; /* Removed default spacing between input fields */
}

/* Add space between the two input fields */
.login-text-area:not(:last-child) {
    margin-bottom: 1rem; /* 1 rem spacing between the two input fields */
}

.login-text-area:last-child {
    margin-bottom: 3rem; /* 3 rem spacing between last input and "Giriş" button */
}

.login-text-area .login-icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    color: #6ea4ff;
    font-size: 18px;
}

.login-text-input {
    width: 100%;
    padding: 14px 20px 14px 50px; /* Increased padding-left for better spacing */
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background: #f9f9f9;
}

.login-text-input::placeholder {
    color: #999999;
    font-size: 14px;
}

.login-text-input:focus {
    outline: none;
    border-color: #6ea4ff;
    box-shadow: 0 0 5px rgba(110, 164, 255, 0.5);
    background: #ffffff;
}

.login-btn {
    width: 100%;
    padding: 15px;
    border-radius: 30px;
    background: linear-gradient(135deg, #6ea4ff, #4a82e7);
    border: none;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(110, 164, 255, 0.4);
    position: relative;
    overflow: hidden;
    margin-top: 3rem;
}

.login-btn::after {
    content: '';
    position: absolute;
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0.15);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.6s ease;
}

.login-btn:hover::after {
    width: 0%;
    height: 0%;
}

.login-btn:hover {
    background: linear-gradient(135deg, #5a92f7, #3f6ed6);
    box-shadow: 0 6px 10px rgba(110, 164, 255, 0.5);
}

.login-btn:active {
    background: linear-gradient(135deg, #4a82e7, #2e5dc8);
    transform: scale(0.98);
    box-shadow: 0 3px 5px rgba(110, 164, 255, 0.3);
}

.login-btn:disabled {
    background: #a0c4ff;
    cursor: not-allowed;
    box-shadow: none;
}

.login-link {
    color: #0E4579;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    cursor: pointer;
    margin-top: 15px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.login-link:hover {
    color: #6ea4ff;
}

.login-error, .login-message {
    color: #d8000c;
    background-color: #ffbaba;
    padding: 12px 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 14px;
    width: 100%;
    text-align: left;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.login-error {
    background-color: #ffe6e6;
    color: #cc0000;
}

.login-message {
    background-color: #e6ffe6;
    color: #009900;
}

/* Spinner Styles */
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

/* Responsive Design for Login Page */
@media (max-width: 480px) {
    .login-container {
        padding: 40px 30px;
        margin-top: 10px; /* Reduced top margin for smaller screens */
        width: 100%; /* Allow full width on small screens */
        max-width: 95%; /* Slightly increased to fit more content */
    }

    .login-title {
        font-size: 26px; /* Slight reduction on mobile */
    }

    .login-subtitle {
        font-size: 14px; /* Increased to 14px as per requirement */
        margin-bottom: 3rem; /* Maintain 3 rem spacing */
    }

    .login-text-input {
        font-size: 14px;
    }

    .login-btn {
        font-size: 16px;
        padding: 12px;
    }

    .login-error, .login-message {
        font-size: 13px;
    }

    /* Adjust decorative circles for smaller screens */
    .login-container::before,
    .login-container::after {
        width: 40px;
        height: 40px;
    }

    .login-container::before {
        top: -15px;
        left: -15px;
    }

    .login-container::after {
        bottom: -15px;
        right: -15px;
    }
}
