/* src/components/Contact.css */

.contact-page {
    display: flex;
    justify-content: space-around;
    gap: 40px;
    padding: 60px 80px; /* Adjusted padding to move content higher */
    background-color: #f9f9f9;
  }
  
  .section-title {
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: 30px;
    color: #007cfb;
  }
  
  /* Form Section */
  .form-section {
    flex: 1;
    max-width: 550px;
    padding: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    padding: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1.1rem;
  }
  
  .contact-form input:focus,
  .contact-form select:focus,
  .contact-form textarea:focus {
    outline: none;
    border-color: #007cfb;
  }
  
  .submit-button {
    background-color: #007cfb;
    color: white;
    padding: 18px;
    border: none;
    border-radius: 8px;
    font-size: 1.3rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #005bb5;
  }
  
  /* Contact Info Section */
  .contact-info-section {
    flex: 1;
    max-width: 450px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
  }
  
  /* Align icons and text vertically */
  .info-item {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 25px;
    font-size: 1.4rem;
    width: 100%;
  }
  
  /* Ensure consistent icon size and alignment */
  .info-icon {
    min-width: 40px; /* Reserve space for icons to align with each other */
    text-align: center;
    font-size: 2rem;
    color: #007cfb;
  }
  
  .info-item a,
  .info-item span {
    color: #333;
    text-decoration: none;
    line-height: 1.5;
  }
  
  .info-item a:hover {
    text-decoration: underline;
  }
  
  /* Social Media Icons */
  .social-media {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
  }
  
  .social-icon {
    font-size: 3rem;
    color: #007cfb;
    cursor: pointer;
    transition: transform 0.3s, color 0.3s;
  }
  
  .social-icon:hover {
    transform: scale(1.1);
    color: #005bb5;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .contact-page {
      flex-direction: column;
      align-items: center;
      padding: 20px 20px; /* Reduced padding for mobile */
    }
  
    .form-section,
    .contact-info-section {
      width: 100%;
      max-width: 500px; /* Limit max-width to prevent over-stretching */
      padding: 10px; /* Reduced padding */
    }
  
    .contact-form input,
    .contact-form select,
    .contact-form textarea {
      width: 100%; /* Ensure inputs take full width */
      box-sizing: border-box;
    }
  
    .contact-info-section {
      align-items: center; /* Center the contact info */
      text-align: center; /* Center text */
    }
  
    .info-item {
      justify-content: center; /* Center items */
      gap: 10px; /* Reduced gap */
      font-size: 1.2rem; /* Slightly smaller font */
      margin-bottom: 20px; /* Reduced margin */
    }
  
    .section-title {
      font-size: 2rem; /* Slightly smaller title */
      margin-bottom: 20px; /* Reduced margin */
    }
  
    .social-media {
      gap: 20px; /* Reduced gap between icons */
    }
  
    .social-icon {
      font-size: 2.5rem; /* Slightly smaller icons */
    }
  }
  